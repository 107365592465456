body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: arial;

    *::-webkit-scrollbar {
        width: 16px;
    }

    *::-webkit-scrollbar-thumb {
        height: 56px;
        border-radius: 8px;
        border: 4px solid transparent;
        background-clip: content-box;
        background-color: hsl(0, 0%, 67%);
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.PartsPie {
    // position: relative;
    display: inline-block;
}

.card {
    background: #444;
    border-radius: 10px;
    padding: 5px;
}
