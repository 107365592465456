#SidebarBlocker {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 2;
}
.Sidebar {
    position: fixed;
    pointer-events: fill;
    box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.35);
    left: 0;
    top: 0;
    right: auto;
    bottom: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    vertical-align: top;
    background-color: #111;
    color: #ddd;
    font-size: 25px;
    border-color: #444;
    border-style: solid;
    border-width: 0 1px 1px 0;
    box-sizing: content-box;
    // overflow: hidden;
    transition: width 0.3s;
    width: 50px;
    direction: ltr;
    &.expanded,
    &.expanded.narrow {
        width: 240px;
    }
    &.narrow {
        width: 0;
    }

    button {
        // display: block;
        // font-size: 24px;
        width: 100%;
        // height: 25px;
        padding: 0;
        white-space: nowrap;
        &:focus {
            color: #aaffff;
        }
        &:disabled {
            color: #666;
            &:hover {
                color: #111;
            }
        }
    }

    .side-btn-img {
        width: 50px;
        height: 50px;
    }

    #SidebarButtons {
        // flex-grow: 1;
        // overflow-x: hidden;
        // overflow-y: auto;
        // position: relative;
        button {
            width: 100%;
            font-size: 24px;
            height: 50px;
            text-align: left;
            svg {
                width: 50px;
            }
            span.CommandLabel {
                font-size: 15px;
            }
            img {
                width: 32px;
                height: 32px;
                margin: 9px;
                border-radius: 16px;
            }
        }
    }

    // #SidebarFooter {
    //     // position: absolute;
    //     // bottom: 0;
    //     // min-height: 50px;
    //     // width: 50px;
    // }

    .SidebarSection {
        border-color: #444;
        border-width: 0 0 1px 0;
        border-style: solid;
    }
}

// .ButtonsList {
//     .narrow & {
//         height: calc(100% - 50px);
//     }
// }

.ReciterIcon {
    display: inline-block;
    border-radius: 20px;
    margin: 5px;
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    button:disabled & {
        filter: grayscale(100%);
    }
}
