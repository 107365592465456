$wrongTextColor: #f33;
$correctTextColor: greenyellow;
$popupBgColor: #111111e6;
$exerciseBgColor: #111111f2;

//TODO: duplicate with Page.scss
$selectionBgColor: #c8ccf0;
$selectionBgColorDark: rgb(38, 93, 138);

.ModalOverlay {
    position: fixed;
    // left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
    direction: ltr;

    &.modal {
        //block clicks to layers below
        pointer-events: all !important;
    }
}

.CancelButton {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 50px;
    height: 50px;
    color: white;
    font-size: 18px;
    // padding: 10px 15px;
    &:hover {
        background-color: #666;
    }
}

.trans-start {
    .ModalContent {
        opacity: 0;
        top: -20px;
    }
}

.ModalContent {
    pointer-events: all;
    position: absolute;
    right: 0;
    padding: 0 15px 0 15px;
    box-shadow: 0 0px 15px 0px rgba(17, 17, 17, 0.35);
    background-color: $popupBgColor;
    #ExercisePopup & {
        background-color: $exerciseBgColor;
    }
    color: white;
    box-sizing: border-box;
    max-height: 100%;

    [lang="ar"] & {
        direction: rtl;
    }

    .trans-end & {
        transition: opacity 0.5s, top 0.5s, bottom 0.5s, left 0.2s, right 0.2s;
        opacity: 1;
        top: 0;
        &.HiddenPopup {
            top: -20px;
            opacity: 0;
        }
    }

    .Title {
        display: flex;
        flex-direction: row;
        #ExercisePopup & {
            flex-direction: column;
            align-items: stretch;
            .ButtonsBar {
                padding: 5px 0;
            }
        }
        .TitleNote {
            font-size: 11px;
            line-height: 11px;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .TitleButtons {
            flex-grow: 1;
            display: flex;
            align-items: stretch;
            justify-content: stretch;
        }
        flex-wrap: nowrap;
        align-items: center;
        padding: 0 3px 0 50px;
        background-color: #111;
        margin: 0 -15px 0 -15px;
        border-bottom: solid 1px #444;
        min-height: 50px;
        position: relative;
        .PlayerButtons {
            display: flex;
        }

        button {
            font-size: 13px;
            // min-width: 50px;
            // height: 40px;
            svg {
                font-size: 18px;
            }
            // margin: 0 4px;
        }
        .FlexTitle {
            display: inline-block;
            min-width: calc(100% - 115px);
        }
    }
    .PopupBody {
        // flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
        // max-height: calc(100% - 40px);
        margin: 5px 0 15px 0;
        .ButtonsBar {
            margin: 10px 0;
        }
    }
    .OptionRow {
        padding: 5px 0;
        margin: 5px 0 10px;
        // text-align: center;
        label {
            display: block;
            width: 100%;
            > span {
                display: inline-block;
                margin: 0 12px;
                min-width: 100px;
                width: 36%;
                text-align: right;
                [lang="ar"] & {
                    text-align: left;
                }
            }
            > * {
                vertical-align: middle;
            }
        }
        input[type="radio"] {
            vertical-align: text-top;
        }
        svg {
            font-size: 25px;
        }
    }
    .FieldRow {
        div {
            display: inline-block;
        }
        margin: 10px 0;
    }
    .FieldLabel {
        width: 38%;
        text-align: right;
        [lang="ar"] & {
            text-align: left;
        }
        padding: 0 10px;
        box-sizing: border-box;
    }
    .FieldValue {
        width: 23%;
        text-align: right;
        input {
            width: 100%;
            color: white;
            border: none;
            font-size: 25px;
            background-color: #ffffff30;
            vertical-align: middle;
        }
    }
    .FieldAction {
        width: 23%;
        padding: 0 10px;
        box-sizing: border-box;
    }

    // .ActionBar {
    //     margin: 20px 0 0 0;
    //     text-align: center;
    // }

    .FieldAction {
        button {
            background-color: #666;
            border: none;
            color: white;
            padding: 5px 15px;
            font-size: 17px;
            vertical-align: middle;

            &:hover {
                background-color: #111;
            }
        }
    }

    .CancelButton {
        font-size: 24px;
    }

    .CommandsList {
        text-align: center;
        direction: ltr;
        padding: 25px 0;
        button {
            width: 80px;
            height: 80px !important;
            position: relative;
            font-size: 25px;
            border-radius: 6px;
            margin: 3px;
            // margin: 1px;
            // background-color: #444;
            .CommandLabel {
                font-size: 12px;
            }
            .ReciterIcon {
                // display: inline-block;
                // border-radius: 6px;
                // height: 34px;
                // width: 34px;
                // margin-bottom: -12px;
                // background-repeat: no-repeat;
                // background-position: center;
                // background-size: cover;
            }
        }
    }
    #SearchHistory {
        padding: 5px 0;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
        // background-color: #444;
        height: 44px;
        button {
            font-size: 15px;
            padding: 3px 5px;
            border: solid 1px silver;
            margin: 0 3px;
        }
    }
    .link {
        cursor: pointer;
        padding: 5px;
        &:hover {
            color: #ffffaa;
            background-color: #666;
        }
        * {
            pointer-events: none;
        }
    }

    .ResultInfo {
        direction: ltr;
        float: left;
        [lang="ar"] & {
            float: right;
            direction: rtl;
        }
        color: cyan;
        background-color: #444;
        border-radius: 5px;
        // display: inline-block;
        min-width: 100px;
        // text-align: left;
        padding: 0 5px;
    }

    .ResultsInfo {
        color: yellow;
        height: 35px;
        line-height: 30px;
    }

    .ResultsList {
        // margin-top: 20px;
        // direction: rtl;
        cursor: default;
        margin: 0;
        // padding-inline-start: 30px;
        // overflow: auto;
        .MoreResults {
            width: 100%;
            background-color: #888;
            padding: 10px;
            color: #111;
            cursor: pointer;
            &:hover {
                background-color: #ddd;
            }
        }
        .ResultItem {
            display: block;
            width: 100%;
            text-align: initial;
            direction: rtl;
            span {
                pointer-events: none;
            }
            // text-align: right;
            font-size: 16px;
            line-height: 25px;
            .hiword {
                color: yellow;
            }
            padding: 5px 0;
            border-bottom: solid 1px #444;
            &:last-child {
                border-bottom: none;
            }
            &:hover {
                background-color: #333;
            }
            svg {
                padding: 0 10px;
                pointer-events: initial;
                &:hover {
                    background-color: #111 !important;
                }
            }
        }
    }

    a {
        color: white;
        text-decoration: none;
    }
    .TafseerVerse {
        color: yellow;
        // white-space: normal;
    }
}

#SearchPopup {
    .Title {
        .TypingConsole {
            margin: 0;
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 9px;
            box-sizing: border-box;
            line-height: 20px;
        }
        .ButtonsBar {
            flex-grow: 0 !important;
            min-width: 50px;
        }
    }
    .KeyboardFrame {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #111;
        padding: 10px 5px 0;
        pointer-events: fill;
    }
    // .PopupBody {
    //     margin-top: 0;
    // }
}

#IndicesPopup,
#FavoritesPopup {
    .Title {
        //align-items: stretch;
        justify-items: center;
        align-items: flex-end;
        overflow-x: hidden;

        > span {
            line-height: 35px;
        }
        .ButtonsBar {
            padding: 0;
            margin: 0 4px;
            .CommandButton {
                span {
                    font-size: 12px;
                }
            }
        }
        button {
            // height: 100%;
            // min-width: 60px;
            padding: 0 5px;
            // margin: 0;
            font-size: 20px;
            background-color: #222;
            border-radius: 10px 10px 0 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            &.active {
                color: white;
                background-color: #444;
            }
            &:hover {
                color: white;
            }
            span {
                display: inline-block;
                margin: 0 0 0 5px;
                [lang="ar"] & {
                    margin: 0 5px 0 0;
                }
                font-size: 16px;
            }
            // &.CommandButton {
            //     // height: auto !important;
            // }
            svg {
                font-size: 15px;
            }
        }
    }
    #AKeyboard {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #111;
        z-index: 2;
    }
}

#ExercisePopup {
    // .PopupBody {
    //     margin: 0 -10px;
    // }
    // #AKeyboard {
    //     margin: 0 0 5px 0;
    // }

    #VerseToTest {
        font-size: 25px;
    }
    .Correct {
        color: $correctTextColor;
    }
    .Wrong {
        color: $wrongTextColor;
    }
}

.TafseerView {
    white-space: normal;
    direction: rtl;
    //     .VerseInfo {
    //         float: left;
    //         margin: 0 10px 10px 0;
    //         min-width: 60px;
    //         button {
    //             min-height: 2zpx;
    //         }
    //     }
    .TafseerText {
        // clear: both;
    }
}

.TypingConsole {
    direction: rtl;
    padding: 10px;
    min-height: 21px;
    max-height: calc(100% - 40px);
    // max-height: 92px;
    border: solid 1px #444;
    margin: 5px 0;
    font-size: 18px;
    overflow: auto;
    position: relative;
    &.empty {
        color: #666;
    }
    .TypingCursor {
        display: inline-block;
        width: 2px;
        background-color: #ffffff66;
        height: 20px;
        vertical-align: top;
    }
    .ClearButton {
        position: absolute;
        // right: 10px;
        top: 10px;
        color: #999;
        // [dir="rtl"] & {
        left: 10px;
        right: auto;
        // }
        &:hover {
            color: white;
        }
    }
}

.TrackDuration {
    display: flex;
    margin: 0 5px;
    align-items: center;
}

.BookmarkRow {
    border-bottom: solid 1px #444;
    &:last-child {
        border-bottom: none;
    }
}

.ParaId {
    display: inline-block;
    background-color: #444;
    text-align: center;
    padding: 0 4px;
    margin: 0 5px 0 0;
    [lang="ar"] & {
        margin: 0 0 0 5px;
    }

    &.Chapter {
        padding: 1px 3px;
        border-radius: 12px;
        float: right;
        min-width: 20px;
        [lang="ar"] & {
            float: left;
        }
    }

    &.Verse {
        float: left;
        min-width: 20px;
        font-size: 13px;
        border-radius: 4px;
        background-color: $selectionBgColor;
        color: #111;
        .DarkTheme & {
            color: white;
            background-color: $selectionBgColorDark;
        }
        [lang="ar"] & {
            float: right;
        }
    }
}

.ResultsGroup {
    border-top: solid 1px #444;
    background-color: #222;
    button {
        text-align: initial;
        margin: 0;
        padding: 8px 2px;
    }
    .ResultsGroupHeader {
        display: block;
        width: 100%;
        font-size: 15px;
        &:hover {
            color: cyan;
        }
    }

    &.Expanded {
        background-color: #111;
        .ResultsGroupHeader {
            color: cyan;
            padding-bottom: 5px;
        }
        .ResultsGroupList {
            display: block;
        }
    }

    .ResultsGroupList {
        display: none;
        padding: 0 0 0 20px;

        [lang="ar"] & {
            padding: 0 20px 0 0;
        }
    }
}

#SearchViewToggler {
    border: solid 1px #666;
    font-size: 16px;
    // float: right;
    padding: 3px 12px;
    border-radius: 4px;
    margin: 0 5px 0 0;
    [lang="ar"] & {
        // float: left;
        transform: scaleX(-1);
        margin: 0 0 0 5px;
    }
    &.active {
        background-color: #666;
    }
}
